<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('client.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <form @submit.prevent="submit">
      <div class="form--group row">
        <label class="col-12 col-lg-4">Uuid Klien</label>
        <div class="col-12 col-lg-8">
          {{ clientUuid }}
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
        <label class="col-12 col-lg-4">Nama Klien</label>
        <div class="col-12 col-lg-8">
          <v-text-field type="text" outlined hide-details v-model="name" :readonly="!isEditing" />
          <span class="val-error">{{ validation.firstError('name') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('phone') }">
        <label class="col-12 col-lg-4">Nomor Telepon Klien</label>
        <div class="col-12 col-lg-8">
          <v-text-field type="text" outlined hide-details v-model="phone" :readonly="!isEditing" />
          <span class="val-error">{{ validation.firstError('phone') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('email') }">
        <label class="col-12 col-lg-4">Email Klien</label>
        <div class="col-12 col-lg-8">
          <v-text-field type="text" outlined hide-details v-model="email" :readonly="!isEditing" />
          <span class="val-error">{{ validation.firstError('email') }}</span>
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-4">Catatan</label>
        <div class="col-12 col-lg-8">
          <v-textarea
            type="text"
            outlined
            hide-details
            v-model="notes"
            :readonly="!isEditing"
            rows="5"
          />
        </div>
      </div>
      <div class="mt-6">
        {{ $t('general.bankInfo') }}
      </div>
      <hr class="mt-2 mb-4" />
      <div class="form--group row" :class="{ 'has-error': validation.hasError('selectedBank') }">
        <label class="col-12 col-lg-4">Nama Bank</label>
        <div class="col-12 col-lg-8">
          <multiselect
            name="selectedBank"
            id="selectedBankField"
            v-model="selectedBank"
            :options="banks"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
            :disabled="!isEditing"
          />
          <span class="val-error">{{ validation.firstError('selectedBank') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('accountNumber') }">
        <label class="col-12 col-lg-4">Nomor Rekening</label>
        <div class="col-12 col-lg-8">
          <v-text-field
            type="text"
            outlined
            hide-details
            v-model="accountNumber"
            :readonly="!isEditing"
          />
          <span class="val-error">{{ validation.firstError('accountNumber') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('accountHolder') }">
        <label class="col-12 col-lg-4">Nama Pemilik Rekening</label>
        <div class="col-12 col-lg-8">
          <v-text-field
            type="text"
            outlined
            hide-details
            v-model="accountHolder"
            :readonly="!isEditing"
          />
          <span class="val-error">{{ validation.firstError('accountHolder') }}</span>
        </div>
      </div>
    </form>
    <div class="d-flex align-center justify-center mt-4" v-if="permissions.includes('EDIT')">
      <v-btn color="primary" class="bottom--button" @click="openAgentForm">
        {{ $t('client.btn.assignAgent') }}
      </v-btn>
    </div>
    <div class="d-flex align-center justify-center mt-4" v-if="permissions.includes('EDIT')">
      <v-btn color="primary" class="bottom--button" @click="startEdit" v-if="!isEditing">
        {{ $t('client.btn.editData') }}
      </v-btn>
      <v-btn color="primary" class="bottom--button" @click="submit" v-else>
        {{ $t('client.btn.save') }}
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: { Multiselect },
  data() {
    return {
      format,
      isEditing: false,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      clientUuid: (state) => state.client.form.clientUuid,
      banks: (state) => state.client.form.banks,
    }),
    name: {
      get() {
        return this.$store.state.client.form.name;
      },
      set(value) {
        this.$store.commit('client/form/SET_NAME', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.client.form.phone;
      },
      set(value) {
        this.$store.commit('client/form/SET_PHONE', value);
      },
    },
    email: {
      get() {
        return this.$store.state.client.form.email;
      },
      set(value) {
        this.$store.commit('client/form/SET_EMAIL', value);
      },
    },
    notes: {
      get() {
        return this.$store.state.client.form.notes;
      },
      set(value) {
        this.$store.commit('client/form/SET_NOTES', value);
      },
    },
    selectedBank: {
      get() {
        return this.$store.state.client.form.selectedBank;
      },
      set(value) {
        this.$store.commit('client/form/SET_SELECTED_BANK', value);
      },
    },
    accountHolder: {
      get() {
        return this.$store.state.client.form.accountHolder;
      },
      set(value) {
        this.$store.commit('client/form/SET_ACCOUNT_HOLDER', value);
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.client.form.accountNumber;
      },
      set(value) {
        this.$store.commit('client/form/SET_ACCOUNT_NUMBER', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.client.name.required'));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.client.phone.required'));
    },
    email(value) {
      return Validator.value(value).required(this.$i18n.t('errors.client.email.required'));
    },
  },
  methods: {
    async deleteAction(id) {
      try {
        await this.$store.dispatch('client/delete', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('client.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    startEdit() {
      this.isEditing = true;
    },
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('client/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('client.update.successMsg'),
          'success',
        );
        this.isEditing = false;
        await this.$store.dispatch('client/getDetailInitData', this.$route.params.uuid);
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async openAgentForm() {
      this.$store.commit('client/form/SET_ASSIGNED_AGENT', null);
      this.$modal.show('modal-agent', {
        agent: null,
        commitString: 'client/form/SET_ASSIGNED_AGENT',
        dispatchString: 'client/form/assignAgent',
        dispatchAfterString: 'client/getDetailInitData',
      });
    },
  },
};
</script>
